const axi = require('../assets/axi')
const OSS = require("ali-oss");
import {
	Message,
	MessageBox
} from 'element-ui'
const client = new OSS({
	// yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
	region: "oss-cn-shanghai",
	// 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
	accessKeyId: "LTAI5tEMVZjxdTHAYzsGbanj",
	accessKeySecret: "5QCCo038V8g9gzct7S9PlMSJyAiZtT",
	// 填写Bucket名称。
	bucket: "bxexample",
});

function pushFile(file, imgname) { //上传方法
	console.log(file);
	let data = file.raw //file类型文件
	// imgname传到oss上的名字
	// // 调用上传方法
	client.multipartUpload('OfficialWebsite/invoice/'+imgname, data, {
		progress: function*(percentage) {
			let fileloadingNum = Math.ceil(percentage * 100) + "%";
			console.log(fileloadingNum); // 上传文件进度
		},
	}).then(function(result) { //上传到OSS后调用存入数据库
		//Datas传入数据库的数据
		return result
	}).catch(function(err) {
		console.log(err)
			return err
		Message.error('上传失败')
		// 上传失败,弹出上传失败的消息
	});
};

function delimgs(val) { //删除方法
	console.log(val)
	return new Promise((resolve, reject) => {
		try {
			let result = client.delete(val).then((res) => {
				// axi.post(Durl, data).then((res) => {
				// 	if (res.data.State == 1000) {
						Message.success('删除成功')
				// 		return true
				// 	} else {
				// 		Message.error(res.data.Msg);
				// 	}
				// });
			});
			console.log(result);
		} catch (e) {
			console.log(e);
		}
		resolve()
	})
};



export default {
	pushFile,
	delimgs,
	client
}

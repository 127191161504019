<template>
	<div id="body">
		<div id="abus_banner" v-if="bannerflage">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/ABUSBANNER.jpg">
		</div>
		<div id="abus_main">
			<el-tabs v-model="activeName">
				<el-tab-pane label="公司介绍" name="on">
					<div id="abus_synopsis">
						<p id="Tit">公司简介</p>
						<div id="abus_synopsis_main">
							<div id="abus_synopsis_main_img">
								<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/SYNimg.jpg">
							</div>


							<div id="abus_synopsis_main_text">
								<p>融恒上海）科技有限公司是由创业18年的精英团队和从事20年研发经验的专家共同发起创立，是以“云存储，云计算，大数据，AI智能”为核心技术的高科技互联网公司，专注于企业数字化创新和信息化管理，立志为千万家中小微企业提供移动数字化企业SaaS管理云服务平台，持续为中小微企业数字化转型和企业业务上云服务而努力奋斗。
									公司产品完全自主研发，拥有完全自主知识产权（16项软件著作权），并通过了信息安全管理体系认证（ISO/IEC
									27001:2013）和信息技术服务管理体系认证（ISO/IEC 20000-1:2018）。
								</p>
								<p>公司旗下八大核心产品：<br>
									1，ERP模块（建筑工程、制造加工）<br>
									2，OA办公模块（多行业应用）<br>
									3，商协会管理模块（商会、协会、联合会）<br>
									4，律所OA办公管理模块（律师事务所）<br>
									5，仓储进销存管理模块（贸易、仓储、服装布料）<br>
									6，智能报修模块（物业、生产制造及有产品维护需求的行业）<br>
									7，商超服务管理模块（美容美发、游乐健身、商超零售、棋牌保健等连锁企业）<br>
									8，汽配维修模块（汽配、汽修美容）<br>
									产品自推出以来已被多个行业、多个企业使用，每天为众多客户提供良好的服务体验，我们将通过客户的需求不断完善自己的产品及服务，通过客户的不断积累以更加聚焦细分领域及行业，也将不断投入研发力量和不断更新研发技术以期成为行业引领者。
								</p>
							</div>
						</div>
					</div>
					<div id="Us_data">
						<p class="Ti">服务数据</p>
						<div id="Us_data_flex">
							<div id="Us_data_flex_box">
								<p>20+</p>
								<span>覆盖20+行业解决方案</span>
							</div>
							<div id="Us_data_flex_box">
								<p>200+</p>
								<span>产品完成200+次迭代升级</span>
							</div>
							<!-- <div id="Us_data_flex_box">
								<p>10W+</p>
								<span>10W+用户选择我们</span>
							</div> -->
						</div>
					</div>
					<div id="ZZbox">
						<p>荣誉资质</p>
					</div>
					<div id="swp">
						<el-carousel :interval="4000" type="card" height="600px">
							<el-carousel-item v-for="(item,i) in img" :key="i">
								<img :src="item.url">
							</el-carousel-item>
						</el-carousel>
					</div>
					<div id="ZZbtn">
						<button type="button" @click="activeName='tw'">查看更多>></button>
					</div>
					<div id="culture">
						<div id="culture_main">
							<div id="titss">
								<p>企业文化</p>
							</div>
							<div id="culture_main_flex">
								<div id="culture_main_flex_box">
									<icon-svg class='iconclas' iconClass='lianxiwomen1'></icon-svg>
									<p>
										<icon-svg class='iconclas' iconClass='youjiantou'></icon-svg>我们的愿景
									</p>
									<span>为千万家中小微企业赋能</span>
								</div>
								<div id="culture_main_flex_box">
									<icon-svg class='iconclas' iconClass='shiming'></icon-svg>
									<p>
										<icon-svg class='iconclas' iconClass='youjiantou'></icon-svg>我们的使命
									</p>
									<span>做，让千万家中小微企业用得起的软件 让千万家中小微企业降本增效的软件</span>
								</div>
								<div id="culture_main_flex_box">
									<icon-svg class='iconclas' iconClass='jiazhiguan'></icon-svg>
									<p>
										<icon-svg class='iconclas' iconClass='youjiantou'></icon-svg>我们的价值观
									</p>
									<span>团结 分享 共赢 持续创新 为客户创造价值</span>
								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="荣誉资质" name="tw">
					<div id="Honor">
						<div id="certificateofhonor">
							<p>荣誉证书</p>
							<div id="certificateofhonor_main">
								<div id="certificateofhonor_main_flex">
									<img style="width: 400px;"
										src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/YYZZ.png">
								</div>
							</div>
						</div>
						<div id="Patents">
							<p style="margin-bottom: 2rem;">软件著作权</p>
							<div id="Patents_flex">
								<div id="Patents_flex_box" v-for="(item,i) in Ryimg" :key="i">
									<!-- <img :src="item.Img"> -->
									<viewer :images="Ryimg">
										<img :src="item.url">
									</viewer>
									<p>{{item.name}}</p>
								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="发展历程" name="th">
					<div id="LC">
						<el-timeline>
							<el-timeline-item color='red' timestamp=" 2022年1月" placement="top">
								<el-card>
									<h4>融恒会管理系统上线</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item color='red' timestamp=" 2021年10月" placement="top">
								<el-card>
									<h4>融恒能报修系统上线</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item color='red' timestamp=" 2021年8月" placement="top">
								<el-card>
									<h4>融恒RP系统上线</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item color='red' timestamp="2021年6月" placement="top">
								<el-card>
									<h4>融恒息管理平台美业系统上线</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item color='red' timestamp="2021年4月" placement="top">
								<el-card>
									<h4>融恒上海）科技有限公司正式成立</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item color='red' timestamp="2020年12月" placement="top">
								<el-card>
									<h4>公司OA智能办公系统上线</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item color='red' timestamp="2020年6月" placement="top">
								<el-card>
									<h4>融恒息平台进销存系统上线</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item color='red' timestamp=" 2019年" placement="top">
								<el-card>
									<h4>融恒传染智能监测系统开发上线</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item color='red' timestamp="2018年" placement="top">
								<el-card>
									<h4>公司前身研发部门成立</h4>
								</el-card>
							</el-timeline-item>
						</el-timeline>
					</div>
				</el-tab-pane>
				<el-tab-pane label="联系我们" name="fo">
					<div id="contactus">
						<div id="contactus_header">
							<p class="t">联系我们</p>
							<div id="contactus-flex">
								<div id="contactus-flex-bx">
									<icon-svg class='iconclas' iconClass='shouye'></icon-svg>
									<p>客服电话：4006182019</p>
									<span>(周一至周五 9:00 - 17:30）</span>
									<p>投诉电话：4006182019</p>
								</div>
								<div id="contactus-flex-bx">
									<icon-svg class='iconclas' iconClass='shengchanzhizao'></icon-svg>
									<p>服务支持：4006182019</p>
								</div>
								<div id="contactus-flex-bx">
									<icon-svg class='iconclas' iconClass='40'></icon-svg>
									<p>人员招聘：4006182019</p>
									<p>市场合作：472720628@qq.com</p>
								</div>
							</div>
						</div>
						<div id="contactus_main">
							<div id="contactus_main_bgc">
								<div id="contactus_main_box">
									<p>上海总部</p>
									<span>上海市静安区中铁·中环时代广场</span>
								</div>
							</div>
						</div>
						<div id="contactus_mains">
							<div id="contactus_main_bgcs">
								<div id="contactus_main_boxs">
									<p>深圳分公司</p>
									<span>深圳南山区南山科技园</span>
								</div>
							</div>
						</div>
						<div id="contactus_mainss">
							<div id="contactus_main_bgcss">
								<div id="contactus_main_boxss">
									<p>北京分公司</p>
									<span>北京市海淀区</span>
								</div>
							</div>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="合作代理" name="fi">

					<div id="Loginbox">
						<div id="Loginbox_main" v-if="loflage">
							<img src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/TX.png">
							<button type="button" id="Logbtn" @click="logbtnfun">登录</button>
							<p style="text-align: left;padding-left: 10%;">登录后自由掌控账户信息</p>
							<p v-if="wxflage" style="font-size: 14px;text-align: left;padding-left: 10%;">更多登录：<img
									style="width: 30px;height: 30px;cursor: pointer;" @click="wxlogin"
									src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bindwx.png"></p>
						</div>
						<div id="Loginbox_main_inp" v-show="flase">
							<el-input placeholder="请输入手机号" v-model="Phone">
								<template slot="prepend">账号：</template>
							</el-input>
							<el-input placeholder="请输入验证码" v-model="codes">
								<template slot="append">
									<span style="cursor: pointer;" @click="hcode">{{hqtxt}}</span></template>
							</el-input>
							<button type="button" @click="loginfun">登录</button>
							<p v-if="wxflage" style="font-size: 14px;text-align: left;padding-left: 10%;">更多登录：<img
									style="width: 30px;height: 30px;cursor: pointer;" @click="wxlogin"
									src="http://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bindwx.png"></p>
						</div>
						<div id="PC_TAB" v-show="Pcflage">
							<el-select v-model="value" placeholder="请选择" style="float: right" @change="selfuns">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
							<el-table v-if="!xxflage&&value!='3'" show-summary :data="tableData" style="width: 100%">
								<el-table-column prop="Account" label="账户名称">
								</el-table-column>
								<el-table-column prop="UserName" label="客户名称">
								</el-table-column>
								<el-table-column prop="RegisterTime" label="注册时间">
								</el-table-column>
								<el-table-column prop="Deadline" label="到期时间">
								</el-table-column>
								<el-table-column prop="Renew" label="账户状态">
									<template slot-scope="scope">
										<span v-if="scope.row.Renew=='已交费'"
											style="color:#0dcf0d ;">{{ scope.row.Renew }}</span>
										<span v-else style="color:red ;">{{ scope.row.Renew }}</span>
									</template>
								</el-table-column>
								<el-table-column prop="Money" label="续费金额">
									<template slot-scope="scope">
										<span style="color:#fd3f31 ;">￥{{ scope.row.Money }}</span>
									</template>
								</el-table-column>
							</el-table>
							<el-table v-if="!xxflage&&value=='3'" show-summary :data="Xfdata" style="width: 100%">
								<el-table-column prop="UserName" label="账号">
								</el-table-column>
								<el-table-column prop="GoodsName" label="商品名">
								</el-table-column>
								<el-table-column prop="Price" label="价格">
									<template slot-scope="scope">
										<span style="color:#fd3f31 ;">￥{{ scope.row.Price }}</span>
									</template>
								</el-table-column>
								<el-table-column prop="Time_END" label="付款时间">
								</el-table-column>
							</el-table>
							<p style="margin-top: 30px;" v-if="!xxflage">总客户:{{total}}位</p>
							<el-pagination v-if="!xxflage" background @current-change='pagfun'
								style="float: right;margin-top: 30px;" layout="prev, pager, next" :total="total">
							</el-pagination>
							<div id="mybox" style="height: 455px;" v-if="xxflage">
								<div id="My_main" style="padding-top: 1px; margin: 0;">
									<p style="font-weight: bold;text-align: center;">绑定微信:</p>
									<div id="wximg"
										style="text-align: center; height: 100px;background: linear-gradient(to bottom right,#D3F78D,#B0E7A1);border-radius: 5px;"
										v-if="Infoobj.WeChat">
										<img v-if="Infoobj.WeChat" :src="Infoobj.WeChat.headimgurl">
										<img v-if="!Infoobj.WeChat"
											src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/logo3.png">
										<p v-if="Infoobj.WeChat">{{Infoobj.WeChat.nickname}}</p>
										<p v-if="!Infoobj.WeChat">暂未绑定</p>
									</div>
									<!-- <div id="my_header">
										<p style="font-weight: bold;">名字:{{Infoobj.UserName}}</p>
									</div> -->
									<div
										style="display: flex;justify-content: space-between;width: 90%;margin-left: 5%;height: 60px; line-height: 45px;border-bottom: 1px solid #d4d4d4;">
										<p>名字:</p>
										<p>{{Infoobj.UserName}}</p>
									</div>
									<div
										style="display: flex;justify-content: space-between;width: 90%;margin-left: 5%;height: 60px; line-height: 45px;border-bottom: 1px solid #d4d4d4;">
										<p>上级代理:</p>
										<p>{{Infoobj.Superior}}</p>
									</div>
									<div
										style="display: flex;justify-content: space-between;width: 90%;margin-left: 5%;height: 60px; line-height: 45px;border-bottom: 1px solid #d4d4d4;">
										<p>手机号:</p>
										<p>{{Infoobj.Phone}}</p>
									</div>
									<div
										style="display: flex;justify-content: space-between;width: 90%;margin-left: 5%;height: 60px; line-height: 45px;border-bottom: 1px solid #d4d4d4;">
										<p>注册时间:</p>
										<p>{{Infoobj.RegisterTime}}</p>
									</div>
									<div
										style="display: flex;justify-content: space-between;width: 90%;margin-left: 5%;height: 60px; line-height: 45px;border-bottom: 1px solid #d4d4d4;">
										<p>失效时间:</p>
										<p>{{Infoobj.InvalidTime}}</p>
									</div>
								</div>
							</div>
						</div>
						<div id="Phone_box" v-show="Phoneflage">
							<el-tabs v-model="phoneactiveNames" type="card" @tab-click="handleClick">
								<el-tab-pane label="所属客户" name="first">
									<div id="Phone_box_box" v-for="(item,i) in tableData" :key='i'
										v-if="!xxflage&&value!='3'">
										<div id="Phone_box_box_header">
											<p>账户名称:{{item.Account}}</p>
										</div>
										<div id="Phone_box_box_main">
											<p style="font-weight: bold;">客户名称:{{item.UserName }}</p>
											<p>注册时间:{{item.RegisterTime}}</p>
											<p>到期时间:{{item.Deadline}}</p>
											<p>账户状态:
												<span v-if="item.Renew=='已交费'"
													style="color: #0dcf0d;">{{item.Renew}}</span>
												<span v-else style="color: red;">{{item.Renew}}</span>
											</p>
											<p>续费金额:<span style="font-size: 18px;color: #fd3f31;">￥{{item.Money}}</span>
											</p>
										</div>
									</div>
									<el-pagination v-if="!xxflage" background @current-change='pagfun'
										style="float: right;margin-top: 30px;" layout="prev, pager, next"
										:total="total">
									</el-pagination>
									<p style="margin-top: 30px; font-size: 14px;" v-if="!xxflage">
										总客户:{{total}}位，本页客户总金额{{pic}}元</p>
								</el-tab-pane>
								<el-tab-pane label="到期客户" name="second">
									<div id="Phone_box_box" v-for="(item,i) in tableData" :key='i'
										v-if="!xxflage&&value!='3'">
										<div id="Phone_box_box_header">
											<p>账户名称:{{item.Account}}</p>
										</div>
										<div id="Phone_box_box_main">
											<p style="font-weight: bold;">客户名称:{{item.UserName }}</p>
											<p>注册时间:{{item.RegisterTime}}</p>
											<p>到期时间:{{item.Deadline}}</p>
											<p>账户状态:
												<span v-if="item.Renew=='已交费'"
													style="color: #0dcf0d;">{{item.Renew}}</span>
												<span v-else style="color: red;">{{item.Renew}}</span>
											</p>
											<p>续费金额:<span style="font-size: 18px;color: #fd3f31;">￥{{item.Money}}</span>
											</p>
										</div>
									</div>
									<el-pagination v-if="!xxflage" background @current-change='pagfun'
										style="float: right;margin-top: 30px;" layout="prev, pager, next"
										:total="total">
									</el-pagination>
									<p style="margin-top: 30px; font-size: 14px;" v-if="!xxflage">
										总客户:{{total}}位，本页客户总金额{{pic}}元</p>
								</el-tab-pane>
								<el-tab-pane label="续费客户" name="third">
									<div id="Phone_box_box" v-for="(item,i) in Xfdata" :key='i' v-if="!xxflage"
										style="height: 140px;">
										<div id="Phone_box_box_header" style="height: 25%;">
											<p>账户:{{item.UserName}}</p>
										</div>
										<div id="Phone_box_box_main">
											<p>商品名:{{item.GoodsName}}</p>
											<p>价格:<span style="font-size: 18px; color: #fd3f31;">￥{{item.Price}}</span>
											</p>
											<p>付款时间:{{item.Time_END}}</p>
										</div>
									</div>
									<el-pagination v-if="!xxflage" background @current-change='pagfun'
										style="float: right;margin-top: 30px;" layout="prev, pager, next"
										:total="total">
									</el-pagination>
									<p style="margin-top: 30px; font-size: 14px;" v-if="!xxflage">
										总客户:{{total}}位，本页客户总金额{{pic}}元</p>
								</el-tab-pane>
								<el-tab-pane label="我的信息" name="fourth">
									<div id="mybox" style="height: 455px;" v-if="!xxflage">
										<div id="My_main" style="padding-top: 1px; margin: 0;">
											<p style="font-weight: bold;text-align: center;">绑定微信:</p>
											<div id="wximg"
												style="text-align: center; height: 100px;background: linear-gradient(to bottom right,#D3F78D,#B0E7A1);border-radius: 5px;"
												v-if="Infoobj.WeChat">
												<img v-if="Infoobj.WeChat" :src="Infoobj.WeChat.headimgurl">
												<img v-if="!Infoobj.WeChat"
													src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/logo3.png">
												<p v-if="Infoobj.WeChat">{{Infoobj.WeChat.nickname}}</p>
												<p v-if="!Infoobj.WeChat">暂未绑定</p>
											</div>
											<!-- <div id="my_header">
												<p style="font-weight: bold;">名字:{{Infoobj.UserName}}</p>
											</div> -->
											<div
												style="display: flex;justify-content: space-between;width: 90%;margin-left: 5%;height: 60px; line-height: 45px;border-bottom: 1px solid #d4d4d4;">
												<p>名字:</p>
												<p>{{Infoobj.UserName}}</p>
											</div>
											<div
												style="display: flex;justify-content: space-between;width: 90%;margin-left: 5%;height: 60px; line-height: 45px;border-bottom: 1px solid #d4d4d4;">
												<p>上级代理:</p>
												<p>{{Infoobj.Superior}}</p>
											</div>
											<div
												style="display: flex;justify-content: space-between;width: 90%;margin-left: 5%;height: 60px; line-height: 45px;border-bottom: 1px solid #d4d4d4;">
												<p>手机号:</p>
												<p>{{Infoobj.Phone}}</p>
											</div>
											<div
												style="display: flex;justify-content: space-between;width: 90%;margin-left: 5%;height: 60px; line-height: 45px;border-bottom: 1px solid #d4d4d4;">
												<p>注册时间:</p>
												<p>{{Infoobj.RegisterTime}}</p>
											</div>
											<div
												style="display: flex;justify-content: space-between;width: 90%;margin-left: 5%;height: 60px; line-height: 45px;border-bottom: 1px solid #d4d4d4;">
												<p>失效时间:</p>
												<p>{{Infoobj.InvalidTime}}</p>
											</div>
										</div>
									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
					</div>
				</el-tab-pane>
				<el-tab-pane label="企业招聘" name="se">
					<div id="zp">
						<el-collapse v-model="activeNames" accordion>
							<el-collapse-item title="软件销售经理" name="1">
								<div id="mxbox">
									<p id="TIT">岗位职责:</p>
									<p id="text">1.负责客户的开拓及公司分配线索跟进</p>
									<p id="text">2.开拓新市场，发展新行业客户，增加产品销售范围</p>
									<p id="text">3.了解客户需求，为客户提供服务解决方案</p>
								</div>
								<div id="mxbox">
									<p id="TIT">任职要求:</p>
									<p id="text">1. 有互联网软件或SaaS相关产品销售经验</p>
									<p id="text">2. 熟悉教育、金融等行业业务特性和场景</p>
									<p id="text">3. 能够较好地了解客户采购流程、把控回款周期</p>
									<p id="text">4. 具有较强的商务谈判及演讲能力</p>
									<p id="text">5. 专科及以上学历，市场营销、计算机相关专业</p>
								</div>
								<div id="mxbox">
									<p id="TIT">薪资待遇及福利:</p>
									<p id="text">1、底薪8000-20000 +绩效奖金+年终奖+五险一金+双休</p>
									<p id="text">2、工作时间:8:30~17:30或12:30~18:30 国定节假日按规定执行</p>
								</div>
							</el-collapse-item>
							<el-collapse-item title="软件销售工程师" name="2">
								<div id="mxbox">
									<p id="TIT">岗位职责:</p>
									<p id="text">1. 负责客户的开拓及公司分配线索跟进</p>
									<p id="text">2. 开拓新市场，发展新行业客户，增加产品销售范围</p>
									<p id="text">3. 了解客户需求，为客户提供服务解决方案</p>
								</div>
								<div id="mxbox">
									<p id="TIT">任职要求:</p>
									<p id="text">1. 有互联网软件或SaaS相关产品销售经验</p>
									<p id="text">2. 熟悉教育、金融等行业业务特性和场景</p>
									<p id="text">3. 能够较好地了解客户采购流程、把控回款周期</p>
									<p id="text">4. 具有较强的商务谈判及演讲能力</p>
									<p id="text">5. 专科及以上学历，市场营销、计算机相关专业</p>
								</div>
								<div id="mxbox">
									<p id="TIT">薪资待遇及福利:</p>
									<p id="text">1、底薪5000-10000 +绩效奖金+年终奖+五险一金+双休</p>
									<p id="text">2、工作时间:8:30~17:30或12:30~18:30 国定节假日按规定执行</p>
								</div>
							</el-collapse-item>
							<el-collapse-item title="软件售后服务工程师" name="3">
								<div id="mxbox">
									<p id="TIT">岗位职责:</p>
									<p id="text">1.维护本公司软件、指导客户使用本公司开发的软件</p>
									<p id="text">2.负责本公司的客服的日常工作，完成本公司各项服务指标</p>
									<p id="text">3.参与、监督、评估外包团队客服人员的各项日常工作以及售前、售中、售后各环节</p>
									<p id="text">4.收集客户信息，妥善处理客户的不满和意见，进行客；户需求分析，向运营及相关部门提出合理化建议；</p>
									<p id="text">5.根据运营部门业务要求，配合营销活动的执行和实施；</p>
									<p id="text">6.处理售后问题（退货审核、投诉处理、软件系统的指导及配货）</p>
								</div>
								<div id="mxbox">
									<p id="TIT">任职要求:</p>
									<p id="text">1、高中以上学历；打字60字/分钟</p>
									<p id="text">2、有1年以上应用类软件售后及客户服务类工作经验者优先</p>
									<p id="text">3、具有良好的协调和沟通能力、人际交往能力和语言表达能力</p>
								</div>
								<div id="mxbox">
									<p id="TIT">薪资待遇及福利:</p>
									<p id="text">1、底薪5000-8000 +绩效奖金+年终奖+五险一金+双休</p>
									<p id="text">2、工作时间:8:30~17:30或12:30~18:30 国定节假日按规定执行</p>
									<p id="text">3、售后客服-客服组长-客服主管 ，并且公司对有能力的人才提供跨岗晋级的机会！！！</p>
								</div>
							</el-collapse-item>
							<el-collapse-item title="网站美工" name="4">
								<div id="mxbox">
									<p id="TIT">岗位职责:</p>
									<p id="text">1、熟练运用PS</p>
									<p id="text">2、对图像处理有扎实的功底。要求懂精修，对图像处理有1年以上的经验</p>
									<p id="text">3、有主图、详情页、有首页设计经验</p>
									<p id="text">4、工作效率高！刻苦耐劳，积极主动。对工作富有责任感、抗压能力强</p>
									<p id="text">5、为人正直。性格开朗、乐观</p>
								</div>
								<div id="mxbox">
									<p id="TIT">任职要求:</p>
									<p id="text">1.能熟练运用photoshop</p>
									<p id="text">2.最少2年以上淘宝美工经验</p>
									<p id="text">3.工作勤恳、责任心强，有良好沟通和理解能力，良好的团队合作精神，能够接受工作压力和挑战，工作态度端正、耐心细致</p>
								</div>
								<div id="mxbox">
									<p id="TIT">薪资待遇及福利:</p>
									<p id="text">1. 做五休二，早上9点到下午6点，中午一个小时吃饭时间</p>
									<p id="text">2. 我们是公司独立研发，品牌大，人员多，有充足的学习交流空间</p>
									<p id="text">3. 定期组织培训，提升员工技能</p>
								</div>
								<div id="mxbox">
									<p id="TIT">加分项:</p>
									<p id="text">有以下行业经验：电子商务</p>
								</div>
							</el-collapse-item>
						</el-collapse>
					</div>
				</el-tab-pane>
				<el-tab-pane label="代理商家" name="dl">
					<el-result icon="success" title="" subTitle="请根据提示进行操作">
						<template slot="extra">
							<el-button type="primary" size="medium" @click="$router.push('/Agent/Agent')">点击查看</el-button>
						</template>
					</el-result>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div id="boxs">
			<div id="boxs_main" v-if="bannerflage">
				<div id="boxs_main_box">
					<icon-svg class='iconclas' iconClass='19'></icon-svg>
					<div id="boxs_main_box_text">
						<p>20+</p>
						<span>覆盖20+行业解决方案</span>
					</div>
				</div>
				<div id="boxs_main_box">
					<icon-svg class='iconclas' iconClass='huanbao'></icon-svg>
					<div id="boxs_main_box_text">
						<p>200+</p>
						<span>产品完成200+次迭代升级</span>
					</div>
				</div>
				<!-- <div id="boxs_main_box">
					<icon-svg class='iconclas' iconClass='dizhi'></icon-svg>
					<div id="boxs_main_box_text">
						<p>10W+</p>
						<span>10W+用户选择我们</span>
					</div>
				</div> -->
			</div>
		</div>
		<div id="footbox" v-if="bannerflage">
			<div id="smb">
				<p>融恒让管理更轻松</p>
				<span>不止是专业，更是对客户的量身订制，创新的数字化管理平台，打破传统的开发方式</span>
				<div id="btn">
					<button style="background-color: #fd3f31;color: #FFFFFF;" type="button"
						@click="zcfun">立即免费注册</button>
					<button type="button" @click="$router.push('/downAPP')">下载手机APP</button>
					<button type="button" @click="$router.push('/store')">免费获取报价</button>
				</div>
			</div>
		</div>
		<footers></footers>
	</div>
</template>

<script>

	import footers from './FOOTER.vue'
	const axi = require('../assets/axi.js')
	import Imgfun from '../components/UpImg.js'
	const OSS = require('ali-oss');
	const client = new OSS({
		// yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
		region: 'oss-cn-shanghai',
		// 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
		accessKeyId: 'LTAI5tEMVZjxdTHAYzsGbanj',
		accessKeySecret: '5QCCo038V8g9gzct7S9PlMSJyAiZtT',
		// 填写Bucket名称，例如examplebucket。
		bucket: "bxexample",
	});
	export default {
		components: {
			footers
		},
		computed: {
			monitor() {
				return this.$store.state.abtab
			}
		},
		data() {
			return {
				activeNames: '0',
				activeName: '',
				phoneactiveNames: "first",
				pic: 0,
				codes: "",
				Phone: "",
				flase: false,
				Pcflage: false,
				Phoneflage: false,
				loflage: true,
				xxflage: false,
				hqtxt: "获取验证码",
				Sen: 60,
				img: [{
					url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/credential/融恒能ERP软件APP.jpg'
				}, {
					url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/credential/融恒销存管理系统.jpg'
				}, {
					url: 'https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/credential/融恒A办公系统.jpg'
				}],
				Ryimg: [], //软著
				tableData: [],
				options: [{
					value: '1',
					label: '所属客户'
				}, {
					value: '2',
					label: '到期客户'
				}, {
					value: '3',
					label: '续费客户'
				}, {
					value: '4',
					label: '我的信息'
				}],
				value: '1',
				SSpage: 1,
				total: 0,
				Infoobj: {},
				Xfdata: [],
				bannerflage: true,
				// ZHXILIST:[],
				wxflage: true,
				token: "token",
				a: '12'
			}
		},
		mounted() {
			try {
				let result = client.list({
					prefix: 'OfficialWebsite/credential/',
					// 设置正斜线（/）为文件夹的分隔符。
					delimiter: '/'
				}).then((result) => {
					let IMgarr = result.objects.slice(1, result.objects.length)
					for (let i = 0; i < IMgarr.length; i++) {
						IMgarr[i].name = IMgarr[i].name.split('OfficialWebsite/credential/')[1]
						IMgarr[i].name = IMgarr[i].name.split('.')[0]
					}
					console.log(IMgarr)
					this.Ryimg = IMgarr
				})
			} catch (e) {
				console.log(e);
			}



			let token = JSON.parse(sessionStorage.getItem('token'));
			console.log(this.$route.query.tab)
			console.log(token)
			var browser = {
				versions: function() {
					var u = navigator.userAgent,
						app = navigator.appVersion;
					return { //移动终端浏览器版本信息
						trident: u.indexOf('Trident') > -1, //IE内核
						presto: u.indexOf('Presto') > -1, //opera内核
						webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
						gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
						mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
						ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
						android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
						iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
						iPad: u.indexOf('iPad') > -1, //是否iPad
						webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
					};
				}(),
				language: (navigator.browserLanguage || navigator.language).toLowerCase()
			}
			if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
				var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
				if (ua.match(/MicroMessenger/i) == "micromessenger") {
					this.wxflage = true
				} else {
					this.wxflage = false
				}
			} else {
				this.wxflage = true
			}
			if (token) {
				this.loflage = false
				if (window.innerWidth > 720) {
					this.Pcflage = true
				} else {
					this.Phoneflage = true
				}
				this.Belongstothecustomer()
			}
			console.log(this.$store.state.abtab)

			if (this.$route.query.tab) {
				this.activeName = this.$route.query.tab
			} else {
				this.activeName = this.$store.state.abtab
			}
			let Urlss = window.location.href
			// let Urlss='http://www.rhdgj.com/?code=061p0fll2mSb484KNYml2NGnIV2p0flC&state=2#/aboutUs'
			// let tokens = JSON.parse(sessionStorage.getItem('token'))
			if (!token) {
				if (Urlss.split('?')[1]) {
					let codes = Urlss.split('?')[1].split("&")[0].split("=")[1]
					let Type = null
					if (Urlss.split('?')[1].split("&")[1].split("=")[1].split('#')) {
						Type = Urlss.split('?')[1].split("&")[1].split("=")[1].split("#")[0]
					} else {
						Type = Urlss.split('?')[1].split("&")[1].split("=")[0]
					}
					console.log(codes)
					this.a = +Type + '，' + codes
					axi.post('Agent/AgentSignInWeChat?Code=' + codes + '&Type=' + Type).then((res) => {
						console.log(res)
						if (res.data.Code == 1000) {
							let token = res.data.Dto
							this.token = res.data.Dto + '，' + Type + '，' + codes
							sessionStorage.setItem('token', JSON.stringify(token))
							this.token = codes + ' , type' + Type
							this.loflage = false
							if (window.innerWidth > 720) {
								this.Pcflage = true
							} else {
								this.Phoneflage = true
							}
							this.$nextTick(() => {
								this.Belongstothecustomer()
							})
						} else {
							this.$message.error(res.data.Msg)
							this.loflage = true
							if (window.innerWidth > 720) {
								this.Pcflage = false
							} else {
								this.Phoneflage = false
							}
						}
					})
					this.activeName = 'fi'
					// this.Belongstothecustomer()
				}
			}
			
		},
		methods: {
			
			handleClick(tab, event) {
				console.log(tab.label);
				if (tab.label == '所属客户') {
					this.Belongstothecustomer()
				}
				if (tab.label == '到期客户') {
					this.expire()
				}
				if (tab.label == '续费客户') {
					this.xffun()
				}
				if (tab.label == '我的信息') {
					this.myinfo()
				}
			},
			zcfun() { //注册
				window.open('http://rhdgjzc.ronghengzn.com/', '_blank');
			},
			logbtnfun() {
				$("#Loginbox_main").toggle(1000, function() {
					$("#Loginbox_main_inp").show(1000, function() {})
				})
			},
			hcode() {
				if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.Phone)) {
					if (this.Sen < 60) {
						console.log('无效')
					} else {
						axi.post('Agent/PhoneCode?Phone=' + this.Phone).then((res) => {
							console.log(res)
							if (res.data.Code != 1000) {
								this.$message.error(res.data.Msg)
								if (res.data.Dto.NextTime) {
									res.data.Dto.NextTime = res.data.Dto.NextTime.split('.')[0].replace('T', ' ')
									let dats = new Date()
									let Ndates = new Date(res.data.Dto.NextTime)
									let Sens = Ndates - dats
									Sens = Sens / 1000
									console.log(Sens)
									this.Sen = Sens.toFixed(0)
								}
							}
						})
						let time = setInterval(() => {
							this.Sen = this.Sen - 1
							if (this.Sen < 1) {
								this.hqtxt = '获取验证码'
								clearTimeout(time);
								this.Sen = 60
							} else {
								this.hqtxt = '(' + this.Sen + ')重新获取'
							}
						}, 1000)
					}
				} else {
					this.$message.error('请输入正确的手机号')
				}
			},
			loginfun() {
				if (this.Phone == '') {
					this.$message.info('请输入手机号')
					return
				}
				if (this.codes == '') {
					this.$message.info('请输入验证码')
					return
				}
				this.selfun()

			},
			selfun() {
				let data = {
					Phone: this.Phone,
					Code: this.codes
				}
				axi.post('Agent/AgentSignIn', data).then((res) => {
					console.log(res)
					if (res.data.Code == 1000) {
						let token = res.data.Dto
						sessionStorage.setItem('token', JSON.stringify(token))
						this.Belongstothecustomer()
						if (window.innerWidth > 720) {
							$("#Loginbox_main_inp").toggle(1000, function() {
								$("#PC_TAB").show(1000, function() {})
							})
						} else {
							$("#Loginbox_main_inp").toggle(1000, function() {
								$("#Phone_box").show(1000, function() {})
							})
						}
					} else {
						this.$message.error(res.data.Msg)
					}
				})
			},
			// 所属客户
			Belongstothecustomer() {
				let data = {
					PageIndex: this.SSpage
				}
				console.log(data)
				axi.post('Agent/MyCustomer', data).then((res) => {
					console.log(res)
					if (res.data.Code == 1000) {
						this.total = res.data.Count
						let pic = 0
						for (let i = 0; i < res.data.Dto.length; i++) {
							if (res.data.Dto[i].Deadline.split('.')[1]) {
								res.data.Dto[i].Deadline = res.data.Dto[i].Deadline.split('.')[0].replace('T', ' ')
							} else {
								res.data.Dto[i].Deadline = res.data.Dto[i].Deadline.replace('T', ' ')
							}
							if (res.data.Dto[i].RegisterTime.split('.')[1]) {
								res.data.Dto[i].RegisterTime = res.data.Dto[i].RegisterTime.split('.')[0].replace(
									'T', ' ')
							} else {
								res.data.Dto[i].RegisterTime = res.data.Dto[i].RegisterTime.replace('T', ' ')
							}
							// res.data.Dto[i].RegisterTime = res.data.Dto[i].RegisterTime.replace('T', ' ')
							if (res.data.Dto[i].Renew) {
								res.data.Dto[i].Renew = '已交费'
							} else {
								res.data.Dto[i].Renew = '未交费'
							}
							pic += res.data.Dto[i].Money
						}
						this.pic = pic
						this.tableData = res.data.Dto
					} else {
						this.$message.error(res.data.Msg)
					}
				})
			},
			// 到期客户
			expire() {
				axi.post("Agent/ExpireCustomer?PageIndex=" + this.SSpage).then((res) => {
					console.log(res)
					if (res.data.Code == 1000) {
						this.total = res.data.Count
						let pic = 0
						for (let i = 0; i < res.data.Dto.length; i++) {
							if (res.data.Dto[i].Deadline.split('.')[1]) {
								res.data.Dto[i].Deadline = res.data.Dto[i].Deadline.split('.')[0].replace('T', ' ')
							} else {
								res.data.Dto[i].Deadline = res.data.Dto[i].Deadline.replace('T', ' ')
							}
							if (res.data.Dto[i].RegisterTime.split('.')[1]) {
								res.data.Dto[i].RegisterTime = res.data.Dto[i].RegisterTime.split('.')[0].replace(
									'T', ' ')
							} else {
								res.data.Dto[i].RegisterTime = res.data.Dto[i].RegisterTime.replace('T', ' ')
							}
							// res.data.Dto[i].Deadline = res.data.Dto[i].Deadline.replace('T', ' ')
							// res.data.Dto[i].RegisterTime = res.data.Dto[i].RegisterTime.replace('T', ' ')
							if (res.data.Dto[i].Renew) {
								res.data.Dto[i].Renew = '已交费'
							} else {
								res.data.Dto[i].Renew = '未交费'
							}
							pic += res.data.Dto[i].Money
						}
						this.pic = pic
						this.tableData = res.data.Dto
					} else {
						this.$message.error(res.data.Msg)
					}
				})
			},
			selfuns(val) {
				console.log(val)
				if (val == '1') {
					this.Belongstothecustomer()
					this.xxflage = false
				}
				if (val == '2') {
					this.SSpage = 1
					this.expire()
					this.xxflage = false
				}
				if (val == '3') {
					this.SSpage = 1
					this.xffun()
					this.xxflage = false
				}
				if (val == '4') {
					this.xxflage = true
					this.myinfo()
				}
			},
			// 我的信息
			myinfo() {
				axi.post('/Agent/MyMessage').then((res) => {
					console.log(res)
					if (res.data.Code == 1000) {
						res.data.Dto.RegisterTime = res.data.Dto.RegisterTime.split('.')[0].replace("T", ' ')
						if (res.data.Dto.InvalidTime) {
							res.data.Dto.InvalidTime = res.data.Dto.InvalidTime.split('.')[0].replace("T", ' ')
						}
						this.Infoobj = res.data.Dto
					} else {
						this.$message.error(res.data.Msg)
					}
				})
			},
			// 续费客户
			xffun() {
				axi.post('Agent/RenewCustomer?PageIndex=' + this.SSpage).then((res) => {
					console.log(res)
					if (res.data.Code == 1000) {
						this.total = res.data.Count
						let pic = 0
						for (let i = 0; i < res.data.Dto.length; i++) {
							res.data.Dto[i].Price = (res.data.Dto[i].Price / 100)
							res.data.Dto[i].Time_END = res.data.Dto[i].Time_END.replace("T", " ")
							pic += res.data.Dto[i].Price
						}
						this.pic = pic
						this.Xfdata = res.data.Dto
					} else {
						this.$message.error(res.data.Msg)
					}
				})
			},
			pagfun(val) {
				console.log(val)
				this.SSpage = val
				if (this.value == '1') {
					this.Belongstothecustomer()
				}
				if (this.value == '2') {
					this.expire()
				}
				if (this.value == '3') {
					this.xffun()
				}
			},
			// 微信登录
			wxlogin() {
				// axi.post("Agent/AgentSignInWeChat?Code=").then((res)=>{
				// 	console.log(res)
				// })
				// console.log('微信登录')
				// var obj = new WxLogin({
				//  self_redirect:true,
				//  id:"login_container",
				//  appid: "wxdfd7b4ec777253a2",
				//  scope: "snsapi_login",
				//  redirect_uri: "http%3A%2F%2Fwww.rhdgj.com%2F%23%2FaboutUs",
				//  state: "",
				//  style: "",
				//  href: ""
				//  });
				var browser = {
					versions: function() {
						var u = navigator.userAgent,
							app = navigator.appVersion;
						return { //移动终端浏览器版本信息
							trident: u.indexOf('Trident') > -1, //IE内核
							presto: u.indexOf('Presto') > -1, //opera内核
							webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
							gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
							mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
							ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
							android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
							iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
							iPad: u.indexOf('iPad') > -1, //是否iPad
							webApp: u.indexOf('Safari') == -1 //是否web应该程序，没有头部与底部
						};
					}(),
					language: (navigator.browserLanguage || navigator.language).toLowerCase()
				}
				if (browser.versions.mobile) { //判断是否是移动设备打开。browser代码在下面
					var ua = navigator.userAgent.toLowerCase(); //获取判断用的对象
					if (ua.match(/MicroMessenger/i) == "micromessenger") {
						window.location.href =
							'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc5a84247c3c9948f&redirect_uri=http%3A%2F%2Fwww.rhdgj.com%2F%23%2FaboutUs&response_type=code&scope=snsapi_base&state=2#wechat_redirect'
					}
				} else {
					window.location.href =
						'https://open.weixin.qq.com/connect/qrconnect?appid=wxdfd7b4ec777253a2&redirect_uri=http%3A%2F%2Fwww.rhdgj.com%2F%23%2FaboutUs&response_type=code&scope=snsapi_login&state=1#wechat_redirect'
					// window.location.href='https://open.weixin.qq.com/sns/explorer_broker?appid=wxc5a84247c3c9948f&redirect_uri=http%3A%2F%2Fwww.rhdgj.com&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
				}

			}

		},
		watch: {
			monitor() {
				console.log(this.$store.state.abtab)
				this.activeName = this.$store.state.abtab
			},
			'activeName': function(newVal) {
				console.log(newVal)
				if (newVal == 'fi') {
          console.log(456)
					if (window.innerWidth < 720) {
						this.bannerflage = false
					}
				} else if(newVal == 'dl'){
          console.log(112123123)
            this.$router.push('/Agent/Agent')
        }else {
					this.bannerflage = true
				}
				// this.fullname = this.firstname + '-' + newVal
			}
		},
	}
</script>

<style scoped lang="less">
	@media screen and (min-width:720px) {

		//电脑
		#abus_banner {
			width: 100%;
			height: 700px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		#Loginbox {
			width: 80%;
			// height: 600px;
			margin-left: 10%;

			#Loginbox_main {
				width: 250px;
				height: 350px;
				// background-color: #F06431;
				box-shadow: -1px 1px 15px #e3e2e2;
				border-radius: 5px;
				margin: 0 auto;
				padding-top: 50px;
				text-align: center;

				img {
					width: 50%;
				}

				button {
					width: 60%;
					height: 40px;
					border-radius: 20px;
					background-color: #fd3f31;
					color: #FFFFFF;
					margin-top: 30px;
					margin-bottom: 10px;
				}
			}

			#Loginbox_main_inp {
				width: 350px;
				height: 300px;
				// background-color: #F06431;
				box-shadow: -1px 1px 15px #e3e2e2;
				border-radius: 5px;
				margin: 0 auto;
				padding-top: 50px;
				text-align: center;

				.el-input-group {
					margin-bottom: 30px;
					width: 80%;
					margin-left: 3%;
				}

				button {
					width: 60%;
					height: 40px;
					border-radius: 20px;
					background-color: #fd3f31;
					color: #FFFFFF;
					margin-top: 30px;
					margin-bottom: 10px;
				}
			}
		}

		#abus_main {
			width: 100%;
		}

		/deep/.el-tabs__nav {
			width: 80%;
			//margin-left: 10%;
		}

		/deep/.el-tabs__item {
			width: 16.6%;
			height: 70px;
			line-height: 70px;
			font-size: 20px;
			text-align: center;
		}

		/deep/.el-tabs__item.is-active {
			color: #fd3f31;
		}

		/deep/.el-tabs__item:hover {
			color: #fd3f31;
		}

		/deep/.el-tabs__active-bar {
			width: 14% !important;
			background-color: #fd3f31;

		}

		#abus_synopsis {
			width: 80%;
			min-height: 600px;
			margin-left: 10%;
			text-align: center;
			padding-top: 3%;

			#Tit {
				font-size: 40px;
			}

			#abus_synopsis_main {
				width: 80%;
				margin-left: 10%;
				height: 85%;
				display: flex;
				justify-content: space-around;
				align-items: center;

				#abus_synopsis_main_img {
					width: 40%;
					height: 100%;

					img {
						max-width: 100%;
						height: 100%;
					}
				}

				#abus_synopsis_main_text {
					width: 50%;
					height: 60%;

					p {
						font-size: 16px;
						color: #333333;
						margin-top: 20px;
						text-indent: 2em;
						line-height: 35px;
						text-align: left;
					}
				}
			}
		}

		#Us_data {
			width: 100%;
			height: 500px;
			background-color: #F9F9F9;
			text-align: center;
			padding-top: 3%;

			.Ti {
				font-size: 40px;
			}

			#Us_data_flex {
				width: 60%;
				margin-left: 20%;
				height: 82%;
				display: flex;
				justify-content: space-around;
				align-items: center;
				flex-wrap: wrap;

				#Us_data_flex_box {
					width: 26%;
					height: 60%;
					padding-top: 2%;
					transition: all .8s;
					cursor: pointer;

					p {
						font-size: 36px;
						color: #fd3f31;
					}
				}

				#Us_data_flex_box:hover {
					width: 28%;
					height: 70%;
					background-color: #FFFFFF;
					border-radius: 10px;
				}
			}
		}

		#swp {
			width: 60%;
			height: 360px;
			margin-left: 20%;

			// display: flex;
			/deep/.el-carousel--horizontal {
				width: 500px;
				margin: 0 auto;
			}

			/deep/.el-carousel__container {
				height: 300px !important;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		#ZZbox {
			text-align: center;
			height: 150px;
			font-size: 36px;
			line-height: 150px;
		}

		#ZZbtn {
			width: 100%;
			height: 100px;
			display: flex;
			justify-content: center;
			align-items: center;

			button {
				width: 187px;
				height: 51px;
				border: 1px solid #fd3f31;
				border-radius: 5px;
				text-align: center;
				line-height: 49px;
				font-size: 17px;
				color: #fd3f31;
				background-color: #FFFFFF;
				margin-top: -50px;
			}
		}

		#culture {
			width: 100%;
			height: 500px;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/cybgc.jpg');
			background-size: 100% 100%;

			#culture_main {
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.5);
				padding-top: 2%;

				#titss {
					text-align: center;
					color: #FFFFFF;
					font-size: 36px;
				}

				#culture_main_flex {
					width: 60%;
					height: 80%;
					margin-left: 20%;
					display: flex;
					justify-content: space-around;
					align-items: center;

					#culture_main_flex_box {
						width: 30%;
						height: 95%;
						text-align: center;
						padding-top: 2%;
						color: #FFFFFF;
						transition: all .8s;

						.iconclas {
							font-size: 160px;
						}

						p {
							font-size: 30px;
							width: 80%;
							margin-left: 10%;
							height: 20%;
							border-bottom: 1px solid #ffffff;
							line-height: 60px;

							.iconclas {
								font-size: 30px;
							}
						}

						span {
							display: block;
							width: 80%;
							margin-left: 10%;
						}
					}

					#culture_main_flex_box:hover {
						background-color: rgba(0, 0, 0, 0.3);
						border-radius: 10px;
						width: 32%;
						height: 100%;
					}
				}
			}
		}

		#footbox {
			width: 100%;
			height: 300px;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/usbgi.jpg');
			background-size: 100% 100%;
			text-align: center;

			#smb {
				width: 100%;
				height: 100%;
				padding-top: 3%;
				background-color: rgba(255, 255, 255, 0.7);

				p {
					font-size: 36px;
				}

				span {
					font-size: 22px;
				}

				#btn {
					width: 80%;
					margin-left: 10%;
					margin-top: 2%;

					button {
						width: 180px;
						height: 50px;
						margin-left: 20px;
						border: 1px solid #fd3f31;
						color: #fd3f31;
						border-radius: 5px;
						background-color: rgba(0, 0, 0, 0);
					}
				}

			}

		}

		#boxs {
			width: 100%;
			height: 150px;

			#boxs_main {
				width: 60%;
				margin-left: 20%;
				height: 100%;
				display: flex;
				justify-content: space-around;

				#boxs_main_box {
					width: 25%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					.iconclas {
						font-size: 50px;
						color: #B2B2B2;
					}

					#boxs_main_box_text {
						margin-left: 5%;

						p {
							font-size: 22px;
							margin: 0;
							color: #fd3f31;
						}

						span {
							color: #B2B2B2;
						}
					}
				}
			}
		}

		#Honor {
			width: 80%;
			min-height: 1500px;
			margin-left: 10%;

			#certificateofhonor {
				text-align: center;
				width: 100%;
				height: 30%;

				p {
					font-size: 36px;
					margin-top: 5%;
					margin-bottom: 3%;
				}

				#certificateofhonor_main {
					height: 100%;
					width: 100%;

					#certificateofhonor_main_flex {}
				}

			}

			#Patents {
				width: 100%;
				height: 70%;
				text-align: center;
				// margin-top: 5%;
				font-size: 36px;
				margin-top: 30px;

				#Patents_flex {
					width: 100%;
					height: 90%;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-wrap: wrap;

					#Patents_flex_box {
						width: 22%;
						height: 31%;
						transition: all .8s;
						margin-left: 2%;
						margin-top: 20px;

						img {
							max-width: 45%;
							cursor: pointer;

						}

						p {
							margin: 0;
							font-size: 18px;
							margin-top: 20px;
							font-weight: bold;
						}
					}

					#Patents_flex_box:hover {
						width: 25%;
						height: 33%;
					}
				}
			}
		}

		#LC {
			width: 60%;
			margin-left: 30%;

			/deep/.el-timeline-item__content {
				width: 50%;
			}
		}

		/deep/.el-card {
			height: 140px;

			.el-card__body {
				height: 100%;

				h4 {
					display: block;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 20px;
				}

			}
		}

		/deep/.el-timeline-item__timestamp {
			color: #fd3f31;
		}

		#contactus {
			width: 100%;
			height: 100%;

			#contactus_header {
				width: 80%;
				height: 600px;
				margin-left: 10%;
				text-align: center;
				margin-top: 2%;
				margin-bottom: 2%;
				box-shadow: 1px 1px 27px 0px rgb(0 0 0 / 9%);
				padding-top: 3%;

				.t {
					font-size: 36px;
				}

				#contactus-flex {
					width: 100%;
					height: 84%;
					// margin-top: 2%;
					// background-color: #FFF000;
					display: flex;
					justify-content: space-between;
					align-items: center;

					#contactus-flex-bx {
						width: 31%;
						height: 80%;
						background-color: #FFFFFF;
						border-right: 1px solid #EFEFEF;
						padding-top: 6%;
						transition: all .8s;

						.iconclas {
							font-size: 80px;
							color: #fd3f31;
						}

						p {
							text-align: center;
							font-size: 18px;
							color: #333333;
							line-height: 38px;
							margin-top: 1rem;

						}

						span {
							font-size: 14px;
							color: #999999;
						}
					}

					#contactus-flex-bx:hover {
						width: 33.3%;
						height: 85%;
					}
				}
			}

			#contactus_main {
				width: 100%;
				height: 500px;
				background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/sh.jpg');
				background-size: 100% 100%;

				#contactus_main_bgc {
					width: 100%;
					height: 100%;
					background-color: rgba(255, 255, 255, 0.4);
					// padding-top: 10%;
					display: flex;
					justify-content: center;
					align-items: center;

					#contactus_main_box {
						width: 20%;
						height: 60%;
						background-color: #FFFFFF;
						margin-right: 50%;
						padding-left: 5%;
						padding-top: 5%;

						p {
							font-size: 30px;
						}

						span {
							font-size: 15px;
							display: block;
							width: 60%;
						}
					}
				}
			}

			#contactus_mains {
				width: 100%;
				height: 500px;
				margin-top: 2%;
				background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/SZ.jpg');
				background-size: 100% 100%;

				#contactus_main_bgcs {
					width: 100%;
					height: 100%;
					background-color: rgba(255, 255, 255, 0.4);
					// padding-top: 10%;
					display: flex;
					justify-content: center;
					align-items: center;

					#contactus_main_boxs {
						width: 20%;
						height: 60%;
						background-color: #FFFFFF;
						margin-left: 50%;
						padding-left: 5%;
						padding-top: 5%;

						p {
							font-size: 30px;
						}

						span {
							font-size: 15px;
							display: block;
							width: 60%;
						}
					}
				}
			}

			#contactus_mainss {
				width: 100%;
				height: 500px;
				margin-top: 2%;
				background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bj.jpg');
				background-size: 100% 100%;

				#contactus_main_bgcss {
					width: 100%;
					height: 100%;
					background-color: rgba(255, 255, 255, 0.4);
					// padding-top: 10%;
					display: flex;
					justify-content: center;
					align-items: center;

					#contactus_main_boxss {
						width: 20%;
						height: 60%;
						background-color: #FFFFFF;
						margin-right: 50%;
						padding-left: 5%;
						padding-top: 5%;

						p {
							font-size: 30px;
						}

						span {
							font-size: 15px;
							display: block;
							width: 60%;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width:720px) {

		//手机
		#abus_banner {
			width: 100%;
			height: 200px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		#Loginbox {
			width: 100%;
			// height: 600px;

			#Loginbox_main {
				width: 250px;
				height: 350px;
				// background-color: #F06431;
				box-shadow: -1px 1px 15px #e3e2e2;
				border-radius: 5px;
				margin: 0 auto;
				padding-top: 50px;
				text-align: center;

				img {
					width: 50%;
				}

				button {
					width: 60%;
					height: 40px;
					border-radius: 20px;
					background-color: #fd3f31;
					color: #FFFFFF;
					margin-top: 30px;
					margin-bottom: 10px;
				}
			}

			#Loginbox_main_inp {
				width: 350px;
				height: 300px;
				// background-color: #F06431;
				box-shadow: -1px 1px 15px #e3e2e2;
				border-radius: 5px;
				margin: 0 auto;
				padding-top: 50px;
				text-align: center;

				.el-input-group {
					margin-bottom: 30px;
					width: 80%;
					margin-left: 3%;
				}

				button {
					width: 60%;
					height: 40px;
					border-radius: 20px;
					background-color: #fd3f31;
					color: #FFFFFF;
					margin-top: 30px;
					margin-bottom: 10px;
				}
			}
		}

		#abus_main {
			width: 100%;
		}

		/deep/.el-tabs__nav {
			width: 100%;
		}

		/deep/.el-tabs__item {
			//width: 16%;
			padding: 0 5px;
			height: 50px;
			line-height: 50px;
			font-size: 12px;
			text-align: center;
		}

		/deep/.el-tabs__item.is-active {
			color: #fd3f31;
		}

		/deep/.el-tabs__item:hover {
			color: #fd3f31;
		}

		/deep/.el-tabs__active-bar {
			width: 14% !important;
			background-color: #fd3f31;

		}

		#abus_synopsis {
			width: 100%;
			height: 1000px;
			text-align: center;
			padding-top: 3%;

			#Tit {
				font-size: 40px;
			}

			#abus_synopsis_main {
				width: 100%;
				height: 85%;
				display: flex;
				justify-content: space-around;
				align-items: center;
				flex-wrap: wrap;

				#abus_synopsis_main_img {
					width: 100%;
					height: 50%;

					img {
						max-width: 100%;
						height: 100%;
					}
				}

				#abus_synopsis_main_text {
					width: 100%;
					height: 50%;

					p {
						font-size: 14px;
						color: #333333;
						margin-top: 20px;
						text-indent: 2em;
						line-height: 35px;
						text-align: left;
					}
				}
			}
		}

		#Us_data {
			width: 100%;
			height: 300px;
			background-color: #F9F9F9;
			text-align: center;
			padding-top: 3%;

			.Ti {
				font-size: 40px;
			}

			#Us_data_flex {
				width: 100%;
				height: 82%;
				display: flex;
				justify-content: space-around;
				align-items: center;
				flex-wrap: wrap;

				#Us_data_flex_box {
					width: 26%;
					height: 60%;
					padding-top: 2%;

					p {
						font-size: 36px;
						color: #fd3f31;
					}
				}
			}
		}

		#swp {
			width: 100%;
			height: 360px;

			// display: flex;
			/deep/.el-carousel--horizontal {
				width: 100%;
				margin: 0 auto;
			}

			/deep/.el-carousel__container {
				height: 300px !important;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}

		#ZZbox {
			text-align: center;
			height: 150px;
			font-size: 36px;
			line-height: 150px;
		}

		#ZZbtn {
			width: 100%;
			height: 100px;
			display: flex;
			justify-content: center;
			align-items: center;

			button {
				width: 187px;
				height: 51px;
				border: 1px solid #fd3f31;
				border-radius: 5px;
				text-align: center;
				line-height: 49px;
				font-size: 17px;
				color: #fd3f31;
				background-color: #FFFFFF;
				margin-top: -50px;
			}
		}

		#culture {
			width: 100%;
			height: 200px;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/cybgc.jpg');
			background-size: 100% 100%;

			#culture_main {
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.5);
				padding-top: 2%;

				#titss {
					text-align: center;
					color: #FFFFFF;
					font-size: 24px;

					p {
						margin: 0;
					}
				}

				#culture_main_flex {
					width: 100%;
					height: 80%;
					display: flex;
					justify-content: space-around;
					align-items: center;

					#culture_main_flex_box {
						width: 33%;
						height: 100%;
						text-align: center;
						color: #FFFFFF;

						.iconclas {
							font-size: 40px;
						}

						p {
							font-size: 14px;
							width: 100%;
							height: 20%;
							border-bottom: 1px solid #ffffff;
							line-height: 30px;

							.iconclas {
								font-size: 18px;
							}
						}

						span {
							display: block;
							width: 90%;
							margin-left: 5%;
							font-size: 12px;
						}
					}
				}
			}
		}

		#footbox {
			width: 100%;
			height: 200px;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/usbgi.jpg');
			background-size: 100% 100%;
			text-align: center;

			#smb {
				width: 100%;
				height: 100%;
				padding-top: 3%;
				background-color: rgba(255, 255, 255, 0.7);

				p {
					font-size: 28px;
				}

				span {
					font-size: 14px;
				}

				#btn {
					width: 100%;
					margin-top: 4%;

					button {
						width: 100px;
						height: 50px;
						font-size: 14px;
						margin-left: 20px;
						border: 1px solid #fd3f31;
						color: #fd3f31;
						border-radius: 5px;
						background-color: rgba(0, 0, 0, 0);
					}
				}

			}

		}

		#boxs {
			width: 100%;
			height: 90px;

			#boxs_main {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-around;

				#boxs_main_box {
					width: 33%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					.iconclas {
						font-size: 30px;
						color: #B2B2B2;
					}

					#boxs_main_box_text {
						margin-left: 5%;

						p {
							font-size: 20px;
							margin: 0;
							color: #fd3f31;
						}

						span {
							color: #B2B2B2;
							font-size: 12px;
						}
					}
				}
			}
		}

		#Honor {
			width: 100%;

			#certificateofhonor {
				text-align: center;
				width: 100%;
				height: 30%;

				p {
					font-size: 36px;
					margin-top: 5%;
					margin-bottom: 3%;
				}

				#certificateofhonor_main {
					height: 100%;
					width: 100%;

					#certificateofhonor_main_flex {}
				}

			}

			#Patents {
				width: 100%;
				height: 70%;
				text-align: center;
				// margin-top: 5%;
				font-size: 36px;

				#Patents_flex {
					width: 100%;
					height: 90%;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;

					#Patents_flex_box {
						width: 45%;
						height: 32%;

						img {
							max-width: 100%;
						}

						p {
							margin: 0;
							font-size: 16px;
							margin-top: 20px;
							font-weight: bold;
						}
					}
				}
			}
		}

		#LC {
			width: 100%;
			// margin-left: 10%;

			/deep/.el-timeline-item__content {
				width: 90%;
			}
		}

		/deep/.el-card {
			height: 100px;

			.el-card__body {
				height: 100%;

				h4 {
					display: block;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 16px;
					font-weight: bold;
				}

			}
		}

		/deep/.el-timeline-item__timestamp {
			color: #fd3f31;
		}

		#contactus {
			width: 100%;
			height: 100%;

			#contactus_header {
				width: 100%;
				height: 600px;
				text-align: center;
				margin-top: 2%;
				margin-bottom: 2%;
				box-shadow: 1px 1px 27px 0px rgb(0 0 0 / 9%);
				padding-top: 3%;

				.t {
					font-size: 32px;
				}

				#contactus-flex {
					width: 100%;
					height: 84%;
					// margin-top: 2%;
					// background-color: #FFF000;
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-wrap: wrap;

					#contactus-flex-bx {
						width: 45%;
						height: 40%;
						background-color: #FFFFFF;
						border-right: 1px solid #EFEFEF;
						padding-top: 6%;

						.iconclas {
							font-size: 60px;
							color: #fd3f31;
						}

						p {
							text-align: center;
							font-size: 14px;
							color: #333333;
							line-height: 30px;
							margin-top: 1rem;

						}

						span {
							font-size: 12px;
							color: #999999;
						}
					}
				}
			}

			#contactus_main {
				width: 100%;
				height: 300px;
				background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/sh.jpg');
				background-size: 100% 100%;

				#contactus_main_bgc {
					width: 100%;
					height: 100%;
					background-color: rgba(255, 255, 255, 0.4);
					// padding-top: 10%;
					display: flex;
					justify-content: center;
					align-items: center;

					#contactus_main_box {
						width: 50%;
						height: 60%;
						background-color: #FFFFFF;
						margin-right: 30%;
						padding-left: 5%;
						padding-top: 5%;

						p {
							font-size: 30px;
						}

						span {
							font-size: 15px;
							display: block;
							width: 90%;
						}
					}
				}
			}

			#contactus_mains {
				width: 100%;
				height: 300px;
				margin-top: 2%;
				background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/SZ.jpg');
				background-size: 100% 100%;

				#contactus_main_bgcs {
					width: 100%;
					height: 100%;
					background-color: rgba(255, 255, 255, 0.4);
					// padding-top: 10%;
					display: flex;
					justify-content: center;
					align-items: center;

					#contactus_main_boxs {
						width: 50%;
						height: 60%;
						background-color: #FFFFFF;
						margin-left: 30%;
						padding-left: 5%;
						padding-top: 5%;

						p {
							font-size: 30px;
						}

						span {
							font-size: 15px;
							display: block;
							width: 90%;
						}
					}
				}
			}

			#contactus_mainss {
				width: 100%;
				height: 300px;
				margin-top: 2%;
				background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bj.jpg');
				background-size: 100% 100%;

				#contactus_main_bgcss {
					width: 100%;
					height: 100%;
					background-color: rgba(255, 255, 255, 0.4);
					// padding-top: 10%;
					display: flex;
					justify-content: center;
					align-items: center;

					#contactus_main_boxss {
						width: 50%;
						height: 60%;
						background-color: #FFFFFF;
						margin-right: 30%;
						padding-left: 5%;
						padding-top: 5%;

						p {
							font-size: 30px;
						}

						span {
							font-size: 15px;
							display: block;
							width: 90%;
						}
					}
				}
			}
		}
	}

	@media screen and (min-width:720px) {
		#zp {
			width: 60%;
			margin-left: 20%;

			/deep/.el-collapse-item__header {
				height: 80px;
				padding-left: 5%;
				font-size: 22px;
			}

			/deep/.el-collapse-item__wrap {
				width: 80%;
				margin-left: 10%;
			}

			#mxbox {
				#TIT {
					font-size: 22px;
					font-weight: bold;
				}

				#text {
					width: 90%;
					margin-left: 5%;
					font-size: 14px;
				}
			}
		}

		#mybox {
			width: 300px;
			height: 300px;
			border-radius: 5px;
			box-shadow: 2px 4px 10px #d9d8d8;
			margin: 0 auto;
			margin-bottom: 30px;
			margin-top: 30px;

			#my_header {
				padding-left: 5%;
				height: 15%;
				line-height: 45px;
				border-bottom: 1px solid #d9d8d8;
			}

			#My_main {
				margin-left: 10%;

				p {
					margin-bottom: 10px;
					margin-top: 10px;
				}

				#wximg {
					border-top: 1px solid #d9d8d8;
					margin-bottom: 10px;

					img {
						width: 50px;
						height: 50px;
						border-radius: 50%;
						margin-right: 10px;
						margin-top: 10px;
					}
				}
			}
		}
	}

	@media screen and (max-width:720px) {
		#zp {
			width: 100%;

			/deep/.el-collapse-item__header {
				height: 80px;
				padding-left: 5%;
				font-size: 20px;
			}

			/deep/.el-collapse-item__wrap {
				width: 90%;
				margin-left: 5%;
			}

			#mxbox {
				#TIT {
					font-size: 20px;
					font-weight: bold;
				}

				#text {
					width: 90%;
					margin-left: 5%;
					font-size: 12px;
				}
			}
		}

		#Phone_box {
			width: 98%;
			margin-left: 1%;

			/deep/.el-tabs__item {
				width: 20%;
			}

			#Phone_box_box {
				width: 94%;
				margin: 10px;
				margin-left: 3%;
				height: 180px;
				// background-color: #FF0000;
				background: linear-gradient(to bottom right, #F8F8F8, #EEEFF1);
				box-shadow: 1px 1px 10px #cbc8c8;
				border-radius: 5px;
				margin-bottom: 15px;

				#Phone_box_box_header {
					height: 20%;
					line-height: 36px;
					padding-left: 5%;
					font-size: 14px;
					font-weight: bold;
					border-bottom: 1px solid #d1d1d1;
				}

				#Phone_box_box_main {
					height: 70%;
					margin-top: 15px;
					padding-left: 10%;
					font-size: 13px;

					p {
						margin-bottom: 5px;
					}
				}
			}
		}

		#mybox {
			width: 300px;
			height: 300px;
			border-radius: 5px;
			box-shadow: 2px 4px 10px #d9d8d8;
			margin: 0 auto;
			margin-bottom: 30px;
			margin-top: 30px;

			#my_header {
				padding-left: 5%;
				height: 15%;
				line-height: 45px;
				border-bottom: 1px solid #d9d8d8;
			}

			#My_main {
				margin-left: 10%;

				p {
					margin-bottom: 10px;
					margin-top: 10px;
				}

				#wximg {
					border-top: 1px solid #d9d8d8;
					margin-bottom: 10px;

					img {
						width: 50px;
						height: 50px;
						border-radius: 50%;
						margin-right: 10px;
						margin-top: 10px;
					}
				}
			}
		}
	}
</style>
